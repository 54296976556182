import { EventEmitter } from "events";
import { Capacitor } from "@capacitor/core";
// hooks
import { getDeviceId } from "../hooks/useDeviceInfo";
// services
import Glient from "./glient";
import User from "./user";
import Gateway from "./gateway";
import Devices from "./devices";
import Constants from "./constants";
import ClusterConstants from "./cluster-constants";
import { sortAlphabetically } from "./l10n";
import { checkGeofenceGateway, checkGeofenceGatewayChanged } from "./geofenceHelper";
import { Storage, StorageKeys} from "./storage";
import { mergeDeep } from "./utils";
// types
import type {
	CmdGatewayInfo, LoginGateway,
	MsgBroadcastAttributeReport, /*MsgBroadcastGatewayActivated,*/ MsgBroadcastGatewayDeactivated, MsgBroadcastGatewayInfo,
} from "../types/message";
import type { GatewayId, GatewayKind, TempUnit, Gateways as GatewaysT, Gateway as GatewayT } from "../types/gateway";
import type { SmartMode } from "../types/misc";

/**
 * An in-memory storage for gateways associated with logged in user.
 * Also, responsible for setting the default gateway, updating a gateway,
 * adding a new device.
 *
 * @class Gateways
 *
 * @event gatewaysChanged
 * @event gatewayDetailsUpdated
 * @event gatewayActivated
 * @event gatewayDeactivated
 * @extends EventEmitter
 */
class Gateways extends EventEmitter {

	#loaded = false;
	#gateways: GatewaysT = [];

	constructor() {
		super();

		this.setMaxListeners(32); // a listener per gateway
	}

	public get loaded() {
		return this.#loaded;
	}

	/**
	 * TODO: needed to temporary fix following error:
	 * Uncaught ReferenceError: can't access lexical declaration '__WEBPACK_DEFAULT_EXPORT__' before initialization
	 */
	public doNotUseThisMethod(): void {
		console.info(User.ready);
	}

	public async fetchGateways(gateways: Array<LoginGateway>): Promise<void> {
		const deviceId = Capacitor.isNativePlatform() ? await getDeviceId() : undefined;

		const tasks = gateways.map((gateway) => (
			new Promise<GatewayT>((resolve, reject) => {
				const cmd = {
					action: "gatewayInfo",
					gatewayId: gateway.id,
					phoneId: deviceId,
				} as const satisfies CmdGatewayInfo;
				Glient.send(cmd, (error, msg) => {
					if (!error && msg?.payload.status === "ok") {
						resolve(mergeDeep(gateway as GatewayT, msg.payload.data));
					} else {
						reject(error);
					}
				});
			})
		));

		const results = await Promise.allSettled(tasks);
		const errors = results.filter((result) => (result.status === "rejected")).map((result) => (result.reason));
		if (errors.length > 0) {
			console.warn("Fetch gateways failed:", errors);
		}
		this.#gateways = results.filter((result) => (result.status === "fulfilled")).map((result) => (result.value)) as GatewaysT;
		this.#loaded = true;
		this.emit("gatewaysLoaded");
		this.emit("gatewaysChanged");
	}

	public getGateways(kind: "all" | GatewayKind = "all"): GatewaysT {
		if (kind === "all") {
			return this.#gateways;
		}
		if (kind === Constants.Gateway.Kind.C2C) {
			return this.#gateways.filter((gateway) => (gateway.kind === Constants.Gateway.Kind.C2C));
		}
		return this.#gateways.filter((gateway) => (gateway.kind === kind || gateway.kind === Constants.Gateway.Kind.GPS));
	}

	public setGateways(gateways: GatewaysT): void {
		this.#gateways = gateways;
		this.emit("gatewaysChanged");
	}

	#addOrUpdateGateway(gateway: GatewayT): void {
		const index = this.#gateways.findIndex((g) => (g.id === gateway.id));
		if (index === -1) {
			this.#gateways.push(gateway);
		} else {
			this.#gateways[index] = { ...this.#gateways[index], ...gateway };
		}
	}

	#removeGateway(gatewayId: GatewayId): void {
		const index = this.#gateways.findIndex((gateway) => (gateway.id === gatewayId));
		if (index > -1) {
			this.#gateways.splice(index, 1); // TODO: .toSpliced()
		}
	}

	#sortFunc(gatewayA: GatewayT, gatewayB: GatewayT) {
		return sortAlphabetically(gatewayA.name || gatewayA.id, gatewayB.name || gatewayB.id); // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
	}

	public getSortedGateways(): GatewaysT {
		return this.getGateways(Constants.Gateway.Kind.Gateway).toSorted(this.#sortFunc);
	}

	public getGatewayIdsOffVisibleDevices(): Array<GatewayId> {
		const selectedGateway = Gateway.selectedGateway;
		if (selectedGateway === undefined) {
			return [];
		}

		if (selectedGateway.rbac & Constants.Gateway.RBAC.Owner) {
			const c2cGatewayIds = this.getGateways(Constants.Gateway.Kind.C2C).map((gateway) => (gateway.id));
			return [selectedGateway.id, ...c2cGatewayIds];
		}

		return [selectedGateway.id];
	}

	/**
	 * Listen to gatewayInfo broadcast and updates the corresponding gateway.
	 * @param {Object} msg
	 * @event gatewaysChanged
	 * @see message-handler.js
	 */
	public handleGatewayInfoBroadcast(msg: MsgBroadcastGatewayInfo): void {
		const oldGateway = this.getGateways().find((gateway) => (gateway.id === msg.payload.gatewayId));
		const gateway = { ...oldGateway ?? {}, ...msg.payload.data } as GatewayT;
		// TODO: remove ugly hack for lat/lng remove
		if (msg.payload.data.latitude === undefined || msg.payload.data.longitude === undefined) {
			delete gateway.latitude;
			delete gateway.longitude;
		}
		this.#addOrUpdateGateway(gateway);
		const selectedGateway = Gateway.selectedGateway;
		if (selectedGateway?.id === gateway.id && (gateway.kind === Constants.Gateway.Kind.Gateway || gateway.kind === Constants.Gateway.Kind.GPS)) {
			Gateway.setDefault(gateway);
			if (!selectedGateway.pairing && gateway.pairing) {
				Gateway.fetchDevicePairingHistory();
			}
		}
		this.emit("gatewaysChanged");
	}

	/**
	 * Listen to attributeReport broadcast and updates the corresponding gateway.
	 * @param {Object} msg
	 * @event gatewayDetailsUpdated
	 * @see message-handler.js
	 */
	public async handleAttributeReportBroadcastAllGateways(msg: MsgBroadcastAttributeReport): Promise<void> {
		const gateway = this.getGateways(Constants.Gateway.Kind.Gateway).find((gateway) => (gateway.id === msg.payload.gatewayId)); // TODO also C2C?
		if (gateway) {
			const isSameGateway = gateway.id === Gateway.selectedGatewayId;
			switch (msg.payload.cluster_id) {
				case "0000":
					for (const childDocument of msg.payload._childDocuments_) {
						switch (childDocument.id) {
							case Constants.Gateway.ReportId.SmartMode:
								gateway.mode = childDocument[childDocument.val] as SmartMode;
								break;
							case Constants.Gateway.ReportId.Timezone:
								gateway.timezone = childDocument[childDocument.val];
								break;
							case Constants.Gateway.ReportId.TemperatureUnit:
								gateway.tempUnit = childDocument[childDocument.val].toUpperCase() as TempUnit; // eslint-disable-line @typescript-eslint/no-unsafe-call
								break;
							default:
								// do nothing
						}
					}
					if (isSameGateway) {
						Gateway.setDefault(gateway, false);
						this.emit("gatewayDetailsUpdated", gateway);
					}
					break;
				case "BC0000":
					// Gateway Backup
					// handled in msg-broadcast-handler
					break;
				case "BC0001":
					console.info("unused cluster-id in attributeReport:", msg.payload.cluster_id, msg.payload);
					break;
				case "BC0002":
					// Rooms
					// handled in msg-broadcast-handler
					break;
				case "BC0003":
					// Scenes
					// handled in msg-broadcast-handler
					break;
				case "BC0004":
					if (isSameGateway) {
						this.emit("gatewayCloudBackupUpdated", msg);
					}
					break;
				case "BC0005": {
					let radius: number | undefined = undefined;
					let latitude: number | undefined = undefined;
					let longitude: number | undefined = undefined;
					for (const childDocument of msg.payload._childDocuments_) {
						switch (childDocument.id) {
							case ClusterConstants.BC0005.Attributes.GeofeceToggle:
								if (Capacitor.isNativePlatform()) {
									await checkGeofenceGateway(gateway, isSameGateway ? Devices.get() : undefined);
								}
								break;
							case ClusterConstants.BC0005.Attributes.Radius:
								radius = childDocument[childDocument.val];
								if (isSameGateway) {
									this.emit("gatewayGeofenceRadiusChanged", msg.payload.gatewayId, radius ?? null); // TODO Currently no geofenceInfo Broadcast
								}
								break;
							case ClusterConstants.BC0005.Attributes.Latitude:
								latitude = childDocument[childDocument.val];
								break;
							case ClusterConstants.BC0005.Attributes.Longitude:
								longitude = childDocument[childDocument.val];
								break;
							default:
								// do nothing
						}
					}
					if (Capacitor.isNativePlatform() && (typeof latitude === "number" || typeof longitude === "number" || typeof radius === "number")) {
						await checkGeofenceGatewayChanged(gateway.id, latitude, longitude, radius);
					}
					break;
				}
				default:
					console.warn("unknown cluster-id in attributeReport:", msg.payload.cluster_id, msg.payload);
			}
		}
	}

	/**
	 * Listen to gatewayActivated broadcast and fires the same event after adding
	 * the new gateway to gateway list.
	 * @param {Object} msg
	 * @event gatewayActivated
	 * @see message-handler
	 */
	// public handleGatewayActivatedBroadcast(msg: MsgBroadcastGatewayActivated): void {
	// 	if (this.#loaded) {
	// 		this.#addOrUpdateGateway(msg.payload.data);
	// 		this.emit("gatewayActivated", msg.payload.data.id);
	// 		this.emit("gatewaysChanged");
	// 	}
	// }

	public handleGatewayDeactivatedBroadcast(msg: MsgBroadcastGatewayDeactivated): void {
		if (this.#loaded) {
			this.#removeGateway(msg.payload.data.id);
			if (msg.payload.data.id === Gateway.selectedGatewayId) {
				Storage.remove(StorageKeys.selectedGatewayId);
				Gateway.setInitGateway();
			}
			this.emit("gatewayDeactivated", msg.payload.data.id);
			this.emit("gatewaysChanged");
		}
	}

}

export default (new Gateways());
